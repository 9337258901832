import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";

import "./styles/tofu-page.sass";
import { TofuPageTemplate } from "./tofu-page";

// eslint-disable-next-line
export const TempehPageTemplate = ({ list }) => (
  <TofuPageTemplate list={list} />
);

TempehPageTemplate.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};

const TempehPage = ({ data, ...props }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Navbar dark externalPage />
      <TempehPageTemplate list={frontmatter.list} />
    </Layout>
  );
};

TempehPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TempehPage;

export const tempehPageQuery = graphql`
  query TempehPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        list {
          name
          desc
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED)
            }
          }
          pros {
            desc
            image {
              publicURL
            }
          }
          infoList {
            name
            content
          }
        }
      }
    }
  }
`;
